import { useEffect, useMemo, useState, createContext } from 'react'
import * as api from '../../api/ItemsApi';
import * as productsApi from '../../api/ProductsApi';
import { getCustomer } from '../../api/CustomersApi';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal';
import Table from '../Table/Table';
import Loader from '../Loader';
import * as configuration from "../../config";
import NoImage from "../../images/RHA-admin-no-image.svg";
import { UKPriceFormat, currencyFormat } from '../../config';
import ContentFrame from '../ContentFrame';
import ItemsForm from './ItemsForm';
import ListBox from '../ListBox';
import Toast from '../Toast';
import * as svg from "../../images/imagesSvg";
import _ from "lodash";

export const ItemsContext = createContext();

const Image = (props) => {
  const imageWidth = props.width;
  try {
    const imagesArr = props.files.split(",");
    if (!Array.isArray(imagesArr)) {
      throw new Error("is not array");
    }
    return (
      <div className={`${imageWidth ? 'w-[' + imageWidth + ']' : 'w-[100px]'}`}><img src={imagesArr[0] != "" ? `${configuration.baseUrl}/images/${imagesArr[0]}` : NoImage}
        onError={e => {
          e.currentTarget.src = NoImage;
        }}
      /></div>
    )
  } catch (e) {
    //console.log(e.message);
    return <></>;
  }
}


function Items(props) {

  const apikey = sessionStorage.getItem('apikey');
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [apiFinished, setApiFinished] = useState(false);
  const [editSelect, setEditSelect] = useState('');
  const [itemId, setItemId] = useState('');
  const [productId, setProductId] = useState(props.productId);
  const [itemType, setItemType] = useState(props.itemType);
  const [selectedModal, setSelectedModal] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPrice, setTotalPrice] = useState(null);
  const [customer, setCustomer] = useState({});
  const [itemTypeLabel, setItemTypeLabel] = useState('');
  const [itemTypeLabelPlural, setItemTypeLabelPlural] = useState('');


  const [unitPriceLabel, setUnitPriceLabel] = useState("");
  const [quantityLabel, setQuantityLabel] = useState("");

  const [sortedIds, setSortedIds] = useState();

  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [toastType, setToastType] = useState("");


  const navigate = useNavigate();
  const params = useParams();

  const doToast = (type, title, message, doRefresh) => {
    if (doRefresh) {
      setRefresh(!refresh);
    }
    setToastType(type);
    setToastTitle(title);
    setToastText(message);
    setIsOpenToast(true);
  }

  const openEditView = (itemId) => {
    if (itemId !== '') {
      setSelectedModal("edit");
      setItemId(itemId);
      setIsOpen(true);
      setEditSelect('');
    }
  }

  const onDeleteHandler = (itemId) => {
    if (itemId !== '') {
      setApiFinished(false);
      api.deleteItem(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry deleted", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }

  const onDuplicateHandler = (itemId) => {
    setApiFinished(false);
    api.getItem(apikey, itemId).then((resp) => {
      let itemData = resp.attributes;
      itemData.id = "";
      api.addItem(apikey, itemData).then((resp) => {
        setItems((prevState) => [...prevState, resp]);
        doToast("success", "Success", "Entry saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    })
  }

  const reOrderHandler = () => {
    let items_order = _.map(sortedIds, 'id').toString();
    api.orderItems(apikey, items_order).then(() => {
      doToast("success", "Success", "Items reordered", true);
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }

  useEffect(() => {
    console.log("ItemType: ", itemType);
    if (itemType === "FAB") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Fabric');
      setItemTypeLabelPlural('Fabrics');
    }
    if (itemType === "MAT") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Component');
      setItemTypeLabelPlural('Components');
    }
    if (itemType === "FIN") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Finish');
      setItemTypeLabelPlural('Finishes');
    }
    if (itemType === "TRA") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Transport');
      setItemTypeLabelPlural('Transport');
    }
    if (itemType === "COU") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Courier');
      setItemTypeLabelPlural('Couriers');
    }
    if (itemType === "CUS") {
      setUnitPriceLabel("Unit Price");
      setQuantityLabel("Qty");
      setItemTypeLabel('Customs');
      setItemTypeLabelPlural('Customs');
    }
  }, []);

  useEffect(() => {
    if (apikey) {
      api.getItems(apikey, productId, itemType)
        .then((res) => {
          if (res) {
            setItems(res);
            setApiFinished(true);
          }
          console.log(res);
        });
    } else {
      navigate('/');
    }
  }, []);


  useEffect(() => {
    setItemId("");
    if (apikey) {
      api.getItems(apikey, productId, itemType)
        .then((res) => {
          if (res) {
            setItems(res);
            setApiFinished(true);
          } else {
            setItems([]);
            setApiFinished(true);
          }
        });
    }
  }, [refresh]);

  useEffect(() => {
    if (apikey && params.customer_id) {
      getCustomer(apikey, params.customer_id)
        .then((res) => {
          if (res) {
            console.log(res.attributes);
            setCustomer(res);
          }
        });
    }
  }, [params]);


  useEffect(() => {
    let itemData = [];
    let totalPrice = 0;
    items.map((item, index) => {
      if (item.attributes.state === 1) {
        // let itemTotal = +item.attributes.unit_price;
        // if (item.attributes.discounts != "") {
        //   item.attributes.discounts.map((discount) => {
        //     itemTotal -= itemTotal * (discount.amount / 100);
        //   });
        // }

        //totalPrice += item.attributes.total;

        itemData.push(
          {
            "id": item.attributes.id,
            "name": item.attributes.name,
            "description": item.attributes.description,
            "supplier": item.attributes.supplier,
            'image': <Image files={item.attributes.files} />,
            "quantity": item.attributes.quantity,
            "unit_price": currencyFormat(item.attributes.unit_price, item.attributes.currency_type),
            "product_id": item.attributes.product_id,
            "total": UKPriceFormat(item.attributes.total),
            "files": item.attributes.files,
            "first_file": item.attributes.first_file,
            "b64File": item.attributes.b64File,
            "code": item.attributes.code,
            "discounts": item.attributes.discounts,
            "markup": item.attributes.markup,
            "currency_type": item.attributes.currency_type,
            "exchange_rate": item.attributes.exchange_rate,
            "itemtype": item.attributes.itemtype,
          }
        )
        //totalPrice += ((item.attributes.quantity * itemTotal) / item.attributes.exchange_rate);
        totalPrice += (+item.attributes.total);
      }
    });
    setTotalPrice(totalPrice)
    setData(itemData);
  }, [items]);

  useEffect(() => {
    if (itemType === "FAB" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "fabrics_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "MAT" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "components_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "FIN" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "finishes_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "TRA" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "transport_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "COU" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "courier_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "CUS" && totalPrice !== null) {
      productsApi.editProduct(apikey, { "customs_total": totalPrice }, productId).then(() => {
        props.setItemsTotal(totalPrice);
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }, [totalPrice]);

  const setItemsStatus = (value) => {
    if (itemType === "FAB" && value !== null) {
      productsApi.editProduct(apikey, { "fabrics_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "MAT" && value !== null) {
      productsApi.editProduct(apikey, { "components_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "FIN" && value !== null) {
      productsApi.editProduct(apikey, { "finishes_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "TRA" && value !== null) {
      productsApi.editProduct(apikey, { "transport_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "COU" && value !== null) {
      productsApi.editProduct(apikey, { "courier_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
    if (itemType === "CUS" && value !== null) {
      productsApi.editProduct(apikey, { "customs_status": value }, productId).then(() => {
        props.setItemsStatus(value);
        doToast("success", "Success", "Entry status saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }



  const columns = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Image',
        accessor: 'image',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => (
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        ),
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: quantityLabel,
        accessor: 'quantity',
      },
      {
        Header: unitPriceLabel,
        accessor: 'unit_price',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    [quantityLabel, unitPriceLabel]
  )

  const addItemHandler = (e) => {
    e.preventDefault();
    setSelectedModal('add');
    setIsOpen(true);
  }

  return (
    <>
      {apikey &&
        <ItemsContext.Provider value={{ refresh, setRefresh, isOpen, setIsOpen }}>
          {!apiFinished && <Loader />}
          {items && apiFinished && data &&
            <>
              <div className='flex h-full'>
                <ContentFrame>
                  <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                    <div>
                      <h2 className='text-slate-700 font-bold text-xl'>{itemTypeLabelPlural}</h2>
                      <p className='text-s text-gray-700 mb-2'><span className='font-bold'>Total:</span> {UKPriceFormat(totalPrice)}</p>
                    </div>
                    <button className='btn flex items-center ml-auto mr-4' onClick={addItemHandler}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                      <span className='ml-2'>Add Item</span>
                    </button>
                    <button className='btn flex items-center' onClick={reOrderHandler}>
                      {svg.reOrderSvg}
                      <span className='ml-2'>Save Items Ordering</span>
                    </button>
                    <div className='ml-4'>
                      <ListBox onSelectChangeHandler={setItemsStatus} selectedIndex={props.itemsStatus} />
                    </div>
                  </div>
                  <Table columns={columns} data={data} setSortedIds={setSortedIds} setData={setData} openEditView={openEditView} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} currentPage={currentPage} setCurrentPage={setCurrentPage} tableType="items" hiddenColumns={['id']} />
                </ContentFrame>
              </div>
            </>
          }
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={selectedModal == 'edit' ? 'Edit '+itemTypeLabel : 'Add '+itemTypeLabel} modalSize={"max-w-3xl"}>
            <ItemsForm productId={props.productId} itemId={itemId} itemType={props.itemType} selectedModal={selectedModal} setSelectedModal={setSelectedModal} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} markup={props.markup} />
          </Modal>
          <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </ItemsContext.Provider>
      }
    </>
  )
}

export default Items