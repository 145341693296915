import React, { useState, useContext, useEffect } from 'react'
import * as api from '../../api/ItemsApi';
import { getCurrency, editProduct } from '../../api/ProductsApi';
import { ItemsContext } from './Items'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Dropzone from '../Dropzone';
import { Tab } from '@headlessui/react';
import Discounts from '../Discounts/Discounts';
import { fabricFormElements, productComponentsFormElements, finishesFormElements, transportFormElements, courierFormElements, customsFormElements } from './ItemsFormElements';
import Toast from '../Toast';
import ProductComponentSupplierForm from '../ProductComponents/ProductComponentSupplierForm';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ItemsForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');
    const [itemId, setItemId] = useState(props.itemId);
    const [productId, setProductId] = useState(props.productId);
    const [itemType, setItemType] = useState(props.itemType);
    const [formElements, setFormElements] = useState([]);
    const [isOpen2, setIsOpen2] = useState(false);
    const [suppliers, setSuppliers] = useState({});
    const [files, setFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [unitPrice, setUnitPrice] = useState();
    const [itemDiscounts, setItemDiscounts] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [currencies, setCurrencies] = useState({});
    const [currencyType, setCurrencyType] = useState("");
    const [exchangeRate, setExchangeRate] = useState(1);
    const [addPercent, setAddPercent] = useState(0);
    const [editorValues, setEditorValues] = useState([]);

    const [discountsArr, setDiscountsArr] = useState([]);
    const [markupsArr, setMarkupsArr] = useState([]);

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(ItemsContext);

    const [itemData, setItemData] = useState({});

    const [supplierFormIsOpen, setSupplierFormIsOpen] = useState(false);
    const [supplier, setSupplier] = useState("");

    const [unitOfMeasurement, setUnitOfMeasurement] = useState("m");

    const [useDefaultMarkup, setUseDefaultMarkup] = useState(1);
    const [markup, setMarkup] = useState(props.markup);

    const [qtyActive, setQtyActive] = useState(0)

    const unitsOfMeasurement = [
        {
            id: 1,
            name: "Meter",
            value: "m"
        },
        {
            id: 2,
            name: "Meters Squared",
            value: "m2"
        }
    ]

    useEffect(() => {
        let markupsAr = [];
        let discountsAr = [];
        itemDiscounts?.map((item) => {
            if (item.amount < 0) {
                markupsAr.push(item);
            }
            if (item.amount > 0) {
                discountsAr.push(item);
            }
        });
        setDiscountsArr(discountsAr);
        setMarkupsArr(markupsAr);
    }, [itemDiscounts]);

    const onSupplierFormClick = (e) => {
        e.preventDefault();
        setSupplierFormIsOpen(true);
    }


    useEffect(() => {

        if (itemType === "FAB") {
            setFormElements(fabricFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, unitsOfMeasurement, unitOfMeasurement, useDefaultMarkup, props.markup));
        }
        if (itemType === "MAT") {
            setFormElements(productComponentsFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, useDefaultMarkup, props.markup, qtyActive));
        }
        if (itemType === "FIN") {
            setFormElements(finishesFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, useDefaultMarkup, props.markup));
        }
        if (itemType === "TRA") {
            setFormElements(transportFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, useDefaultMarkup, props.markup));
        }
        if (itemType === "COU") {
            setFormElements(courierFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, useDefaultMarkup, props.markup));
        }
        if (itemType === "CUS") {
            setFormElements(customsFormElements(suppliers, currencies, currencyType, discountsArr, markupsArr, onSupplierFormClick, useDefaultMarkup, props.markup));
        }

        const discountsStr = JSON.stringify(itemDiscounts).slice(1, -1);

        setItemData((prevState) => {
            return {
                ...prevState,
                "product_id": productId,
                "currency_type": currencyType,
                "exchange_rate": exchangeRate,
                "add_percent": addPercent,
                "discounts": discountsStr,
                "itemtype": itemType,
                "useDefaultMarkup": useDefaultMarkup,
                "qtyActive": qtyActive,
                "quantity": quantity
            }
        });


    }, [suppliers, currencies, currencyType, itemDiscounts, addPercent, discountsArr, markupsArr, unitOfMeasurement, useDefaultMarkup, markup, qtyActive, quantity]);

    useEffect(() => {
        setItemData((prevState) => {
            return {
                ...prevState,
                "supplier": supplier
            }
        });

    }, [supplier]);


    const doToast = (type, title, message, doRefresh) => {
        if (doRefresh) {
            setRefresh(!refresh);
        }
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    useEffect(() => {
        api.getSuppliers(apikey).then((resp) => {
            let itemCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    itemCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setSuppliers(itemCategoryData);
        });
    }, []);

    useEffect(() => {
        getCurrency(apikey).then((resp) => {
            let currencyData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    currencyData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'dataValue': item.attributes.value,
                        }
                    )
                }
            });
            setCurrencies(currencyData);
        });
    }, []);


    useEffect(() => {
        if (itemId !== "") {
            api.getItem(apikey, itemId).then((resp) => {
                setItemData({
                    "name": resp.attributes.name,
                    "description": resp.attributes.description,
                    "supplier": resp.attributes.supplier,
                    "files": resp.attributes.files,
                    //"created_by": userid,
                    //"modified_by": userid,
                    "created_by": resp.attributes.created_by,
                    "modified_by": resp.attributes.modified_by,  
                    "quantity": resp.attributes.quantity,
                    "unit_price": resp.attributes.unit_price,
                    "product_id": resp.attributes.product_id,
                    "total": resp.attributes.total,
                    "code": resp.attributes.code,
                    "discounts": resp.attributes.discounts ? JSON.parse('[' + resp.attributes.discounts + ']') : [],
                    "markup": resp.attributes.markup,
                    "currency_type": resp.attributes.currency_type,
                    "exchange_rate": resp.attributes.exchange_rate,
                    "itemtype": resp.attributes.itemtype,
                    "add_percent": resp.attributes.add_percent,
                    "unit_of_measurement": resp.attributes.unit_of_measurement,
                    "useDefaultMarkup": resp.attributes.markup ? 0 : 1,
                    "qtyActive": resp.attributes.quantity == "1" ? 0 : 1
                });
                console.log(resp.attributes.quantity);
                setFiles(resp.attributes.files ? resp.attributes.files.split(",") : []);
                setQuantity(resp.attributes.quantity);
                setUnitPrice(resp.attributes.unit_price);
                setCurrencyType(resp.attributes.currency_type);
                setExchangeRate(resp.attributes.exchange_rate);
                setItemDiscounts(resp.attributes.discounts ? JSON.parse('[' + resp.attributes.discounts + ']') : []);
                setAddPercent(resp.attributes.add_percent);
                setUnitOfMeasurement(resp.attributes.unit_of_measurement);
                setUseDefaultMarkup(resp.attributes.markup ? 0 : 1);
                setMarkup(resp.attributes.markup ? resp.attributes.markup : props.markup);
                setQtyActive(resp.attributes.quantity == "1" ? 0 : 1);
            });
        }
    }, [itemId]);

    useEffect(() => {
        //        const discountsStr = JSON.stringify(itemDiscounts).slice(1, -1);
        let itemsTotal = +unitPrice * quantity;
        itemDiscounts.map((discount) => {
            itemsTotal -= itemsTotal * (discount.amount / 100);
        });
        setItemData((prevState) => {
            return {
                ...prevState,
                exchange_rate: parseFloat(exchangeRate).toFixed(2),
                //discounts: discountsStr,
                unit_price_no_percent: parseFloat(unitPrice / exchangeRate).toFixed(2),
                eu_percent: parseFloat(itemsTotal / exchangeRate * addPercent / 100).toFixed(2),
                total: parseFloat(itemsTotal / exchangeRate + (currencyType === "EUR" ? itemsTotal / exchangeRate * +addPercent / 100 : 0) + (itemsTotal / exchangeRate + (currencyType === "EUR" ? itemsTotal / exchangeRate * +addPercent / 100 : 0)) * +markup / 100).toFixed(2)
            }
        });
    }, [quantity, unitPrice, exchangeRate, itemDiscounts, addPercent, useDefaultMarkup, markup, qtyActive]);


    const onSubmitHandler = (e, submitType) => {
        e.preventDefault();
        if (props.selectedModal === "edit") {
            api.editItem(apikey, { ...itemData, ...editorValues, modified_by: userid }, itemId).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        } else {
            api.addItem(apikey, { ...itemData, ...editorValues, created_by: userid, modified_by: userid }).then((resp) => {
                setItemId(resp.id);
                props.setSelectedModal("edit");
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
    }

    const [imagesArr, setImagesArr] = useState([]);

    useEffect(() => {
        setNewFiles(imagesArr);
    }, [imagesArr])

    useEffect(() => {
        let newProds = files.concat(newFiles);

        var filtered = newProds.filter(function (el) {
            return el != "";
        });

        setItemData((prevState) => {
            return {
                ...prevState,
                files: filtered.join()
            }
        });
    }, [newFiles])

    const fileUploadHandler = (data) => {
        data.map((item) => {
            setImagesArr((imagesArr) => { return [...imagesArr, item.name.replace(/[^A-Za-z0-9.-]+/g, "")] });
        })
        api.addItemImage(apikey, data).then((resp) => {
            doToast("success", "Success", "Image uploaded");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const fileDeleteHandler = (imagesArr, filename) => {
        setFiles(imagesArr);
        setNewFiles([]);
        api.deleteItemImage(apikey, filename).then((resp) => {
            setItemData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
            doToast("success", "Success", "Image deleted");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const fileRemoveHandler = (imagesArr) => {
        setItemData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }

    const fileReorderHandler = (imagesArr) => {
        setItemData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }




    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
                <button className="btn flex items-center" onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                    <span className='ml-2'>Save</span>
                </button>
                <button className={`btn flex items-center ml-2 ${props.selectedModal === "add" ? "mr-auto" : ""}`} onClick={(e) => {
                    onSubmitHandler(e, "save_and_close");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                {props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    props.onDuplicateHandler(itemId);
                    setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>


                    <span className='ml-2'>Duplicate</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen2(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                    <span className='ml-2'>Delete</span>
                </button>}
            </div>
            <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <Tab.Group selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
                <Tab.List className="flex space-x-1 bg-slate-100 p-1 mb-10">
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }>Details</Tab>

                    {itemId !== "" && <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }>Discounts: {itemDiscounts.map((discount) => {
                        if (discount.amount > 0) {
                            return " " + discount.amount + '%'
                        }
                    })}</Tab>}
                    {itemId === "" && <Tab disabled className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500'
                        )
                    }>Discounts<br /> <span className='text-xs'>(Save Details to enable</span>)</Tab>}

                    {itemId !== "" && (itemType === "FAB" || itemType === "MAT") && <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }>Duties Provision: {itemDiscounts.map((discount) => {
                        if (discount.amount < 0) {
                            return " " + discount.amount * -1 + '%'
                        }
                    })}</Tab>}
                    {itemId === "" && (itemType === "FAB" || itemType === "MAT") && <Tab disabled className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500'
                        )
                    }>Duties Provision<br /> <span className='text-xs'>(Save Details to enable</span>)</Tab>}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <form className="flex w-full flex-wrap justify-between">
                            {!itemData && <Loader />}
                            {itemData && formElements.map((item, index) => (
                                <div key={index} className={item.className ? item.className : 'w-full'}>
                                    <FormElement
                                        elementLabel={item.elementLabel}
                                        elementName={item.elementName}
                                        elementType={item.elementType}
                                        required={item.required}
                                        //elementValue={itemData[item.elementName]}
                                        elementValue={itemData.itemtype === "MAT" && item.elementName === "quantity" && !qtyActive && quantity > 1 ? 1 : itemData[item.elementName]}
                                        elementOptions={item.elementOptions}
                                        fileDeleteHandler={fileDeleteHandler}
                                        fileRemoveHandler={fileRemoveHandler}
                                        fileReorderHandler={fileReorderHandler}
                                        disabled={item.disabled}
                                        editorValue={editorValues[item.elementName]}
                                        elementAction={item.elementAction}
                                        onEditorChange={(newValue, editor) => {
                                            setEditorValues((prevState) => {
                                                return {
                                                    ...prevState, [editor.id]: newValue
                                                }
                                            });
                                        }}
                                        onBlurHandler={(e) => {
                                            if (e.target.required && e.target.value === "") {
                                                e.target.classList.add("bg-red-50");
                                            }
                                        }}
                                        onChangeHandler={(e) => {
                                            setItemData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    [item.elementName]: e.target.value
                                                }
                                            });
                                            // if(itemData.itemtype === "MAT" ){
                                            //     setQuantity(1);
                                            // }
                                            if (e.target.id === "quantity") {
                                                setQuantity(e.target.value);
                                            }
                                            if (e.target.id === "unit_price") {
                                                setUnitPrice(e.target.value);
                                            }
                                            if (e.target.id === "currency_type") {
                                                setCurrencyType(e.target.value);
                                                setExchangeRate(e.target.options[e.target.selectedIndex].dataset.value);
                                            }
                                            if (e.target.id === "add_percent") {
                                                setAddPercent(e.target.value);
                                            }

                                            if (e.target.id === "unit_of_measurement") {
                                                setUnitOfMeasurement(e.target.value);
                                            }

                                            if (e.target.required && e.target.value === "") {
                                                e.target.classList.add("bg-red-50");
                                            } else {
                                                e.target.classList.remove("bg-red-50");
                                            }

                                            if (e.target.id === "markup") {
                                                if(e.target.value !== ""){
                                                    setMarkup(e.target.value);
                                                }else{
                                                    setMarkup(props.markup);
                                                    setUseDefaultMarkup(1);
                                                    console.log("Default Markup",props.markup);
                                                }
                                            }

                                            if (e.target.id === "useDefaultMarkup") {
                                                setUseDefaultMarkup(e.target.checked ? 1 : 0);
                                                setMarkup(e.target.checked ? props.markup : 0);
                                                console.log("Default Markup",props.markup);
                                                setItemData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        "markup": "",
                                                        "useDefaultMarkup": e.target.checked ? 1 : 0
                                                    }
                                                });
                                            }

                                            if (e.target.id === "qtyActive") {
                                                setQtyActive(e.target.checked ? 1 : 0);
                                                setQuantity(e.target.checked ? itemData.quantity : 1);
                                                console.log("Qty Active",qtyActive);
                                                setItemData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        "qtyActive": e.target.checked ? 1 : 0
                                                    }
                                                });
                                            }
                                            

                                        }} />
                                </div>
                            ))}
                            <Dropzone fileUploadHandler={fileUploadHandler} />
                        </form>
                    </Tab.Panel>
                    <Tab.Panel className={'min-h-[800px]'}>
                        <div className="w-full">
                            {itemData && <Discounts setSelectedTabIndex={setSelectedTabIndex} itemId={itemId} discounts={itemDiscounts} setItemDiscounts={setItemDiscounts} discountsArr={discountsArr} markupsArr={markupsArr} type="Discount" />}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel className={'min-h-[800px]'}>
                        <div className="w-full">
                            {itemData && <Discounts setSelectedTabIndex={setSelectedTabIndex} itemId={itemId} discounts={itemDiscounts} setItemDiscounts={setItemDiscounts} discountsArr={discountsArr} markupsArr={markupsArr} type="Markup" />}
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            <Modal isOpen={supplierFormIsOpen} setIsOpen={setSupplierFormIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={'Add Supplier'} modalSize={"max-w-3xl"}>
                <ProductComponentSupplierForm suppliers={suppliers} setSuppliers={setSuppliers} setSupplier={setSupplier} setIsOpen={setSupplierFormIsOpen} itemData={itemData} setItemData={setItemData} />
            </Modal>
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.onDeleteHandler(itemId);
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default ItemsForm